const DEFAULT_LANGUAGE = 'es'; // Idioma por defecto
let currentLanguage = DEFAULT_LANGUAGE;
let translations = {};

function setLanguage(lang) {
	  currentLanguage = lang;
	  // fetch(`../I18n/${lang}.json`)
	  //   .then(response => response.json())
	  //   .then(data => {
	  //     translations = data;
	  //     translateHTML();
	  //   });

	// const baseUrl = window.location.origin;
	// const jsonUrl = `${baseUrl}/I18n/${lang}.json`;
	// fetch(jsonUrl)
	// 	.then((response) => response.json())
	// 	.then((data) => {
	// 		translations = data;
	// 		translateHTML();
	// 	});
}

function translateHTML() {
	const elements = document.querySelectorAll('[data-translate]');

	elements.forEach((element) => {
		const key = element.getAttribute('data-translate');
		if (translations[key]) {
			element.textContent = translations[key];
		}
	});
}

document.addEventListener('DOMContentLoaded', () => {
	setLanguage(currentLanguage);
});
